import styled from "styled-components";
import sideImg from '../../assets/side.svg'

const AboutStyle = styled.section`

    font-family: 'Rubik', sans-serif;
    width: 100%;
    color: #02223C;
    box-sizing: content-box;
    background-color: #F3f3f3;
    display: flex;
    justify-content: center;

    .container {
        padding: 5rem 20px;
        max-width: 1280px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 50%;
        
        .about {
            h1 {
                font-size: 3rem;
                font-weight: 600;
            }
            p {
                margin-top: 24px;
            }
        }

        .side {
            display: flex;
            justify-content: center;
            width: 100%;
            img {
                width: 80%;
            }
        }
        

    }

    @media screen and (max-width: 800px) {
            .container {
                grid-template-columns: 1fr;
                padding: 6rem 20px 3rem 20px;
                text-align: center;
                .about {
                    h1 {
                        font-size: 2rem;
                    }
                }
                .side {
                    margin-top: 36px;
                }
            }    
            
    }
`

export function About() {
    return(
        <AboutStyle id="sobre">
            <div className="container">
                <div className="about">
                    <h1>
                        O que faz a Easy?
                    </h1>
                    <p>A Easy nasceu com a missão de entregar valor ao cliente por meio de seus serviços especializados no desenvolvimento de sites e sistemas, tornando ideias e sonhos em realidade por meio da tecnologia.</p>
                    <p>Com uso de tecnologias avançadas e focadas na experiência do usuário, nossos sistemas e sites entregam o que há de mais moderno.</p>
                    <p>Muito além da melhoria operacional, por meio de estrategias de SEO e HTML semântico oferecemos a nosso cliente sites com acessibilidade e visibilidade nos motores de busca, o colocando na internet.</p>
                </div>
                <div className="side">
                    <img src={sideImg} alt="" />
                </div>
                
            </div>
        </AboutStyle>
    )
}