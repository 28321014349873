import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        line-height: 1.2;
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
    }
`