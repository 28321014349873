import styled from "styled-components";
import server from '../../assets/server.svg'
import site from '../../assets/site.svg'
import app from '../../assets/app.svg'
import integration from '../../assets/api.svg'
import shop from '../../assets/shopping-bag.svg'

const ServicesStyle = styled.section`

    color: #02223C;
    font-family: 'Rubik', sans-serif;
    width: 100%;
    box-sizing: border-box;
    background-color: #Fff;
    display: flex;
    justify-content: center;

    .container {
        padding: 5rem 20px;
        max-width: 1280px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        
        .about {
            h1 {
                font-size: 3rem;
                font-weight: 600;
            }
            p {
                margin-top: 24px;
            }
        }

        .cards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;
            max-width: 1080px;
            width: 100%;
            .card { 
                height: 100%;
                .icon {
                        width: 70px;
                        height: 70px;
                        background-color: #02223C;
                        border-radius: 12px;
                        margin-bottom: 12px;
                        display: flex;
                        flex: 1;
                        align-items: center;
                        justify-content: center;
                        img {
                            filter: invert(0.9);
                            width: 45px;
                            height: 45px;
                        }
                }
                h3 {
                    font-size: 22px;
                   
                }
                p {
                    margin-top: 12px;
                }
                width: 100%;
                background-color: #F3f3f3;
                padding: 20px 20px 0 20px;
                margin-top: 36px;
                border-radius: 16px;
                border: 1px solid #dfdfdf;
                transition: 200ms;

                &:hover {
                    
                    background-color: #02223C;
                    color: #F3f3f3;
                }
            }
        }
        

    }
    
    @media screen and (max-width: 1080px) {
        .container {.cards {
            grid-template-columns: 1fr 1fr 1fr;
        }}
    }
   
    @media screen and (max-width: 800px) {
        .container {
            .cards {
                display: grid;
                grid-template-columns: 1fr;
                min-width: calc(100%);
                max-width: calc(100%);
                justify-self: right;
                margin-top: 30px;
                .card { 
                    
                    max-width: calc(100%);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    h3 {
                    font-size:17px;
                    }
                    .icon {
                        position: relative;
                        margin-bottom: 0;
                        min-width: 70px;
                        min-height: 70px;
                        
                    }
                    .content {
                        position: relative;
                        margin-top: 12px;
                        min-width: calc(100% - 70px);
                    }
                    &:first-child {
                        margin-top: 0px;
                    }
                    width: 100%;
                    background-color: #F3f3f3;
                    padding: 20px;
                    margin-top: 0px;
                    border-radius: 16px;
                    border: 1px solid #dfdfdf;
                }
            }
        }
    }

    


    @media screen and (max-width: 600px) {
            .container {
                grid-template-columns: 1fr;
                padding: 5.7rem 20px 3rem 20px;
                
                .about {
                    text-align: center;
                    h1 {
                        font-size: 2rem;
                        
                    }
                }
            }    
            
    }
`

export function Services() {
    return(
        <ServicesStyle id="services">
            <div className="container">
                <div className="about">
                    <h1>
                        Nossos Serviços
                    </h1>
                    <p>Oferecemos um amplo leque de serviços para as mais diversas necessidades.</p>
                </div>
                <div className="cards">
                    <div className="card">
                        <div className="icon">
                            <img src={app} alt="" />
                        </div>
                        <div className="content">
                            <h3>Desenvolvimento de Sistemas</h3>
                            <p>Sistemas Web ou Desktop para sua empresa.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <img src={site} alt="" />
                        </div>
                        <div className="content">
                            <h3>Criação de Sites</h3>
                            <p>Sites personalizados e responsivos.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <img src={server} alt="" />
                        </div>
                        <div className="content">
                            <h3>Hospedagem, Dominio e SEO</h3>
                            <p>Segurança, velocidade e presença na web com nosso suporte.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <img src={integration} alt="" />
                        </div>
                        <div className="content">
                            <h3>Integrações</h3>
                            <p>Não basta o sistema. Integramos as ferramentas de sua empresa em uma única solução.</p>
                        </div>
                    </div>
                    {/* <div className="card">
                        <div className="icon">
                            <img src={shop} alt="" />
                        </div>
                        <div className="content">
                            <h3>Loja Virtual</h3>
                            <p>Sua loja na internet, integrada aos mais diversos meios de pagamento.</p>
                        </div>
                    </div> */}
                    
                </div>
                
            </div>
        </ServicesStyle>
    )
}