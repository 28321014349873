import styled from "styled-components"
import { useEffect } from "react";
import menu from "../../assets/menu.svg";


const HeaderStyle = styled.header`

    font-family: 'Lexend', sans-serif;

    #videoOverlay {
        width: 100%;
        height: 90vh;
        object-fit: cover;
        position: absolute;
        background-color: #02223C;
        top: 0;
        z-index: -1;
        filter: brightness(0.4);
    }
    .container {
        color: white;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        height: calc(90vh - 80px);
        padding: 0 1rem;
        max-width: 900px;
        margin: 0 auto;
        z-index: 1000;
        h1 {
            font-size: 5rem;
            line-height: 1.2;
            font-weight: 500;
        }

        p {
            font-size: 2.8rem;
            font-weight: 300;
        }
        @keyframes blinker {
        from {opacity: 1.0;}
        to {opacity: 0.0;}
        }
        .blink{
            text-decoration: blink;
            animation-name: blinker;
            animation-duration: 1s;
            animation-iteration-count:infinite;
            animation-timing-function:ease-in;
            direction: alternate;
        }
    }

    .menuFixed {
        height: 0;
        display: none;
    }

    @media screen and (max-width: 800px) {
        .menuFixed {
            position: fixed;
            top: 12px;
            right: 12px;
            height: 51px;
            display: block;
            padding: 6px 7px 5px 7px;
            box-shadow: 2px 3px 12px 0px rgba(0,0,0,0.3);
            border-radius: 50%;
            z-index: 1000000;
            background-color: #02223C;
            img {
                width: 36px;
                filter: invert(1) brightness(1.2);
            }
        }
    }
    @media screen and (max-width: 600px) {
        .container { 
            height: calc(90vh - 80px);
            h1 {
            font-size: 4rem;
            line-height: 0.9;
        } p {
            font-size: 1.5rem;
        }}
    }

`

export function Header() {

    useEffect(() => {
        let el = document.getElementById('sobre');
        var top = 0;

        if(el.tagName !== "BODY") {
            top += el.offsetTop;
        }
        console.log(top);
    }, []);

    function showMenu() {
        document.getElementById('menu').classList.add('show');
        document.getElementById('social').classList.add('show');
    }
    return(
        <HeaderStyle>
            <video autoPlay muted loop id="videoOverlay">
                <source src="https://crivo.netlify.app/static/media/videoloop.1c15f62f749394b5e2ac.mp4" type="video/mp4" />
            </video>
            <div className="container">
                <p>Entregamos resultado</p>
                <h1>SOB MEDIDA</h1>
                <p>para seu negócio<span className="blink">_</span></p>
            </div>
            <div className="menuFixed">
                <img src={menu} onClick={showMenu} alt="" />
            </div>
        </HeaderStyle>
    )
}