import { About } from "./components/About";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Nav } from "./components/Nav";
import { Projects } from "./components/Projects";
import { Services } from "./components/Services";
import { GlobalStyle } from "./createGlobalStyle";

function App() {
  return (
    <>
      <Nav/>
      <Header/>
      <About/>
      <Services/>
      <Projects/>
      <Footer/>
      <GlobalStyle/>
    </>
  );
}

export default App;
