import styled from "styled-components";
import sideImg from '../../assets/side.svg';
import hipercar from '../../assets/hipercar.jpg';
import zerooitoquatro from '../../assets/084.png';
const ProjectsStyle = styled.section`

    font-family: 'Rubik', sans-serif;
    width: 100%;
    color: #02223C;
    box-sizing: content-box;
    background-color: #F3f3f3;
    display: flex;
    justify-content: center;

    .container {
        padding: 5rem 20px;
        max-width: 1280px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .Projects {
            h1 {
                font-size: 3rem;
            }
        }
        .grid_projects {
            margin-top: 32px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 12px;
            .card {
                min-height: 200px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    filter: brightness(0.9);
                }
                img {
                    width: 60%;
                }
            }
        }
    }

    @media screen and (max-width: 760px) {
            .container {
                grid-template-columns: 1fr;
                padding: 6rem 20px 3rem 20px;
                text-align: center;
                .grid_projects {
                    margin-top: 32px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }          

    }

    @media screen and (max-width: 600px) {
            .container {
                grid-template-columns: 1fr;
                padding: 6rem 20px 3rem 20px;
                text-align: center;
                .grid_projects {
                    margin-top: 32px;
                    display: grid;
                    grid-template-columns: 1fr;
                }
            }          

    }
`

export function Projects() {
    return(
        <ProjectsStyle id="projetos">
            <div className="container">
                <div className="Projects">
                    <h1>
                        Nossos Parceiros
                    </h1>
                </div>
                <div className="grid_projects">
                    <a className="card" href="https://hipercarseminovos.com">
                        <img src={hipercar} alt="HiperCar Seminovos" />
                    </a>
                    <a className="card" href="https://084veiculos.com.br">
                        <img src={zerooitoquatro} alt="084 Veículos" />
                    </a>
                </div>
            </div>
        </ProjectsStyle>
    )
}