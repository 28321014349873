import styled from "styled-components";
import logoDark from "../../assets/crivo_light.svg";
import whatsapp from "../../assets/whatsapp.svg";
// import instagram from "../../assets/instagram.svg";
// import facebook from "../../assets/facebook.svg";
import menu from "../../assets/menu.svg";

const NavStyle = styled.nav`

    font-family: 'Rubik', sans-serif;
    width: 100%;
    height: 80px;
    background-color: white;
    display: flex;
    justify-content: center;
    color: #02223C;
    position:sticky;
    top: -1px;
    box-shadow: 4px 0 18px -12px #666;
    z-index: 10000;

    .socialFloat {
            display: none;
        }


    .container {
        padding: 0 20px;
        max-width: 1280px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        

        .logo {
            height: 40px;
            
            img {
                height: 100%;
            }
        }

        .menu {
            .hide {
                display: none;  
            }
            display: flex;
            justify-content: center;
            
            &_item:first-child {
                margin-left: 0;
            }
            &_item {
                color: #02223C;
                font-weight: 500;
                margin-left: 24px;
                text-decoration: none;
            }
        }
        

        .social {
            img {
                height: 28px;
                margin-left: 24px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .hamburger {
            display: none;
        }

    }
    .menuFixed {
            position: fixed!important;
            top: 0;
            left: 48px;
        }

    @media screen and (max-width: 800px) {

        position:sticky;
        top: 0px;
        height: 80px;
        .socialFloat {
            display: flex;
            aspect-ratio: 1/1;
            align-items: center;
            justify-content: center;
            position: fixed;
            bottom: 20px;
            right: 20px;
            border-radius: 50%;
            padding: 20px;
            background-color: #007e11;
            img {
                filter: invert(.9);
            }
        }
        .container {
            justify-content: center;
            .logo {
                height: 40px;
               
            }
            .menu {
                display: none;
                &_item {
                    display: none;
                }
               
            }
            .show {
                box-sizing: border-box;
                    display: block;
                    position: fixed;
                    z-index: 10000000;
                    width: 100%;
                    height: 100vh;
                    background-color: white;
                    top: 0;
                    left: 0;
                    padding-top: 52px;
                    .menu_item {
                        display: block;
                        font-size: 1.5rem;
                        padding: 1.5rem 0;
                        border-bottom: 1px solid grey;
                        margin-left: 0;
                        padding-left: 24px;
                        &:hover {
                            filter: brightness(0.9);
                        }
                    }
                    .hide {
                        position: fixed;
                        right: 20px;
                        font-size: 36px;
                        top: -6px;
                        border-bottom: none;
                    }
            }
            .social {
                display: none;
            }
            .hamburger {
                display: none;
                img {
                    height: 40px;
                }
            }
        }
    }
`

export function Nav() {

    function showMenu() {
        document.getElementById('menu').classList.add('show');
        document.getElementById('social').classList.add('show');
    }

    function closeMenu(e) {
        e.preventDefault();
        document.getElementById('menu').classList.remove('show');
        document.getElementById('social').classList.remove('show');
    }

    function goAction() {
        document.getElementById('menu').classList.remove('show');
        document.getElementById('social').classList.remove('show');
    }

    return(
        <NavStyle>
            <div className="container">
                <div className="logo">
                    <img src={logoDark} alt="" />
                </div>
                <div className="menu" id="menu">
                    <a href="#" onClick={closeMenu} className="menu_item hide">
                    ✕
                    </a>
                    <a href="#sobre" onClick={goAction} className="menu_item">
                        SOBRE
                    </a>
                    <a href="#services" onClick={goAction} className="menu_item">
                        SERVIÇOS
                    </a>
                    <a href="#projetos" onClick={goAction} className="menu_item">
                        PROJETOS
                    </a>
                    <a href="#contato" onClick={goAction} className="menu_item">
                        FALE CONOSCO
                    </a>
                </div>
                <div className="social" id="social">
                    <a href="https://api.whatsapp.com/send?phone=5584987840947&text=Ol%C3%A1!%20Vim%20do%20site%20da%20Easy.%20Poderia%20me%20ajudar?"><img src={whatsapp} className="menu_item"/></a>
                    {/* <img src={facebook} className="menu_item" alt="" />
                    <img src={instagram} className="menu_item" alt="" /> */}
                </div>
                <div className="hamburger">
                    <img src={menu} onClick={showMenu }alt="" />
                </div>
            </div>
            <a href="https://api.whatsapp.com/send?phone=5584987840947&text=Ol%C3%A1!%20Vim%20do%20site%20da%20Easy.%20Poderia%20me%20ajudar?" class="socialFloat">
               
                    <img src={whatsapp} alt="" />
              
            </a>
            {/* <div className="menuFixed">
                <img src={menu} onClick={showMenu} alt="" />
            </div> */}
        </NavStyle>
    )
}