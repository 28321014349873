import styled from "styled-components";
import logoLight from '../../assets/crivo_dark.svg';
import bgSelect from '../../assets/downSelect.svg';

const FooterStyle = styled.section`

    a {
        text-decoration: none;
        color: inherit;
    }

    color: #02223C;
    font-family: 'Rubik', sans-serif;
    width: 100%;
    box-sizing: border-box;
    background-color: #02223C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f3f3f3;

    .container {
        padding: 6rem 20px 0 20px;
        max-width: 1280px;
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
    justify-content: center;
    align-items: center;

        h1 {
            font-size: 2rem;
            &.talk {
                margin-top: 12px;
                font-weight: 400;
                font-size: 1.8rem;
                border-bottom: 2px solid #f3f3f3;
            }
        }

        form {
            max-width: 800px;
            width: 100%;
            min-height: 60px;
            margin-top: 30px;
            display: grid;
            gap: 16px;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: dense;
            padding-bottom: 36px;
            border-bottom: 0.5px solid #1B223B;
            
            label {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                span {
                    margin-bottom: 3px;
                    margin-left: 5px;
                }
            }
            .details {
                grid-column: span 2;
                textarea {
                padding: 12px 18px;
                background-color: #02223C;
                filter: contrast(0.8);
                border: none;
                font-size: 18px;
                color: white;
                border-radius: .3rem;
                width: 100%;
                resize: none;
            }
            
            }
            input, select, button {
                padding: 12px 18px;
                background-color: #02223C;
                filter: contrast(0.8);
                border: none;
                font-size: 18px;
                color: white;
                border-radius: .3rem;
                width: 100%;
                min-height: 45px;
                max-height: 45px;
                &:focus {
                    filter: contrast(0.6);
                }
            }
            select {
                -moz-appearance:none; /* Firefox */
                -webkit-appearance:none; /* Safari and Chrome */
                appearance:none;
                background-image: url(${bgSelect});
                background-repeat: no-repeat;
                background-position-x: right;
                background-position-y: center;
            }
            .send {
                grid-column: span 2;
                background-color: #F49F0A;
                color: black;
                font-weight: 600;
            }
        }
        
        .footer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 1rem 0;
            img {
                height: 40px;
            }
            font-size: .875rem;
        }
    }

    @media screen and (max-width: 800px) {
        .container {
        }
    }

    @media screen and (max-width: 600px) {
        .container {
            
            form {
                grid-template-columns: 1fr;
                .send, .details {
                grid-column: span 1;
            }
            }

            
        }
            
    }
`

export function Footer() {

    function phoneMask(e) {
        e.target.value = e.target.value.replace(/\D/g, '');
    }

    function thanks() {
        alert('Obrigado pela mensagem! Faremos contato em breve.')
    }
    return(
        <FooterStyle id="contato">
            <div className="container" >
                <h1>O que podemos fazer por você?</h1>
                <h1 className="talk">FALE CONOSCO</h1>
                  
                <form onSubmit={thanks} action="https://send.pageclip.co/Z0iAHoFHCeBDdKt3GMcQPr3ZYQOsY04k" className="pageclip-form" method="post">
                    <label htmlFor="contato">
                        <span>Seu Nome ou Empresa: *</span>
                        <input type="text" name="contato" id="contato" required/>
                    </label>
                    <label htmlFor="telefone">
                        <span>Um telefone para contato: *</span>
                        <input type="tel" name="telefone" id="telefone" required onChange={phoneMask}/>
                    </label>
                    <label htmlFor="email">
                        <span>Um e-mail para contato: *</span>
                        <input type="email" name="email" id="email" required/>
                    </label>
                    <label htmlFor="objetivo">
                        <span>O que deseja? *</span>
                        <select name="objetivo" id="objetivo" required>
                            <option></option>
                            <option value="desenvolvimento de sistema">Desenvolvimento de Sistema</option>
                            <option value="desenvolvimento de site">Criação de Site</option>
                            <option value="hospedagem">Site e Hospedagem</option>
                            <option value="suporte">Suporte</option>
                        </select>
                    </label>
                    <label htmlFor="details" className="details" >
                        <span>Detalhe o seu projeto:</span>
                        <textarea name="descricao" id="details" rows="3" ></textarea>
                    </label>
                    <button type="submit" className="send pageclip-form__submit">Enviar mensagem</button>
                    
                </form> 
                <div className="footer">
                    <a href="mailto:contato@easysoftwares.com.br">contato@easysoftwares.com.br</a>
                    <img src={logoLight} alt="" />   
                    
                </div>           
            </div>
        </FooterStyle>
    )
}